import { Button, Grid } from "@mui/material";
import { ReactComponent as ErrorSvg } from "../../assets/images/error.svg";

import { CustomText } from "../../styles/CustomText";
import { useHistory } from "react-router-dom";

function NotFoundPage() {
  const history = useHistory();
  return (
    <Grid container mt="5%">
      <Grid item xs={12}>
        <Grid container justifyContent="center">
          <ErrorSvg width="60%" height="60%" style={{ maxWidth: "200px" }} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CustomText type="bigTitle" mt="20px" align="center">
          요청하신 페이지를 찾을 수 없습니다.
        </CustomText>
        <CustomText type="subtitle" mt="20px" align="center">
          존재하지 않는 주소를 입력하셨거나,
        </CustomText>
        <CustomText type="subtitle" mt="5px" align="center">
          요청하신 페이지의 주소가 변경, 삭제되어 찾을 수 없습니다.
        </CustomText>
        <CustomText type="subtitle" mt="5px" align="center">
          입력하신 주소가 정확한지 다시 한 번 확인해 주시기 바랍니다.
        </CustomText>
        <CustomText type="subtitle" mt="5px" align="center">
          감사합니다.
        </CustomText>
        <Grid item xs={12}>
          <Grid container mt={2} spacing={2} justifyContent="center">
            <Grid item>
              <Button
                onClick={() => {
                  history.goBack();
                }}
                variant="outlined"
              >
                <CustomText type="button" cursorPointer>
                  이전 페이지
                </CustomText>
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="success"
                onClick={() => {
                  history.push("/");
                }}
                variant="outlined"
              >
                <CustomText type="button" cursorPointer>
                  메인 페이지
                </CustomText>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default NotFoundPage;

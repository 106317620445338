import styled from "@emotion/styled";
import { Drawer, IconButton } from "@mui/material";
import { useRecoilState } from "recoil";
import { leftDrawerOpenState } from "../../system/atoms";
import DrawerMenuList from "./components/DrawerMenuList";
import MenuIcon from "@mui/icons-material/Menu";
import { useDeviceType } from "../../hooks/useDeviceType";

const drawerWidth = "240px";

const PersistentDrawerLeft = () => {
  const [open, setOpen] = useRecoilState(leftDrawerOpenState);
  const deviceType = useDeviceType();
  return (
    <>
      <Drawer
        anchor="left"
        open={open}
        hideBackdrop={true}
        variant="persistent"
        onClose={() => setOpen(false)}
      >
        {deviceType === "pc" ? (
          <LeftMenuWrapper>
            <DrawerMenuList type="pc" />
          </LeftMenuWrapper>
        ) : (
          <>
            <DrawerHeader>
              <IconButton
                style={{ marginLeft: "7px", marginTop: "4px" }}
                edge="start"
                onClick={() => setOpen(false)}
                color="inherit"
                aria-label="menu"
                size="large"
              >
                <MenuIcon sx={{ fontSize: 30 }} />
              </IconButton>
            </DrawerHeader>
            <LeftMenuWrapperMobile>
              <DrawerMenuList
                type={deviceType === "mobile" ? "mobile" : "tablet"}
              />
            </LeftMenuWrapperMobile>
          </>
        )}
      </Drawer>
    </>
  );
};
export default PersistentDrawerLeft;
const LeftMenuWrapper = styled.div`
  z-index: 50;
  position: fixed;
  overflow-y: scroll;
  top: 64px;
  left: 0;
  height: calc(100vh - 64px);
  width: ${drawerWidth};
  background-color: rgb(60, 60, 59);
  &::-webkit-scrollbar {
    display: none;
  }
  & * {
    color: #ffffff;
  }
`;

const LeftMenuWrapperMobile = styled.div`
  z-index: 1200;
  position: fixed;
  overflow-y: scroll;
  top: 59px;
  left: 0;
  height: calc(100% - 59px);
  width: 100%;
  background-color: rgb(60, 60, 59);
  &::-webkit-scrollbar {
    display: none;
  }
  & * {
    color: #ffffff;
  }
`;

const DrawerHeader = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
});

import { FC, ReactElement } from "react";
import { Redirect, Route, RouteComponentProps } from "react-router-dom";
import { Urls } from "./Urls";

interface UserRouteProps {
  Component: FC<RouteComponentProps>;
  path?: string;
  exact?: boolean;
}

export default function UserRoute({
  Component,
  path,
  exact = false,
}: UserRouteProps): ReactElement {
  const isUser = true;

  return (
    <Route
      exact={exact}
      path={path}
      render={(props: RouteComponentProps) =>
        isUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: Urls.dashBoard,
              state: {
                requestedPath: path,
              },
            }}
          />
        )
      }
    ></Route>
  );
}

import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse, List, ListItemText, ListItemButton } from "@mui/material";

import { Link, useLocation } from "react-router-dom";

import { CustomText } from "../../../styles/CustomText";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  BoardPageNumber,
  ListTypeState,
  MyInfoState,
  SearchDataAtom,
  leftDrawerOpenState,
  registerLeadStateAtom,
} from "../../../system/atoms";
import { InitListSearchInfo } from "../../../system/types/initObject";
import { useTranslation } from "react-i18next";
import { EmployeeRole } from "../../../types/Employee/Employee";
import { NamespaceKeys } from "../../../@types/i18n";
import {
  adminSettingMenuList,
  commonMenuList,
  managerSettingMenuList,
  userSettingMenuList,
} from "../../../route/Urls";

type DrawerMenuListProps = {
  type: string;
};

const DrawerMenuList = ({ type }: DrawerMenuListProps) => {
  const location = useLocation();

  const [openSections, setOpenSections] = useState<Record<string, boolean>>({});
  const { t } = useTranslation("Menu");
  const { role } = useRecoilValue(MyInfoState);
  const [, setOpen] = useRecoilState(leftDrawerOpenState);
  const [, setPageNumber] = useRecoilState(BoardPageNumber);
  const [, setSearchDt] = useRecoilState(SearchDataAtom);
  const [, setListType] = useRecoilState(ListTypeState);
  const [, setRegisterLeadState] = useRecoilState(registerLeadStateAtom);
  const toggleSection = (key: string) => {
    setOpenSections((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const onChangePageNumber = useCallback(() => {
    setPageNumber(1);
    setSearchDt(InitListSearchInfo);
    setListType("list");
  }, []);

  const closeDrawer = useCallback(() => {
    setOpen(false);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setPageNumber(1);
    setSearchDt(InitListSearchInfo);
    setListType("list");
  }, []);

  const onChangeRegisterLeadState = useCallback(() => {
    setRegisterLeadState("");
  }, []);

  const menuList = useMemo(() => {
    const common = commonMenuList;
    const manager = managerSettingMenuList;
    const admin = adminSettingMenuList;
    const user = userSettingMenuList;
    switch (role) {
      case EmployeeRole.ADMIN:
        return [...common, ...admin];
      case EmployeeRole.MANAGER:
        return [...common, ...manager];
      case EmployeeRole.USER:
        return [...common, ...user];
      default:
        return [...common];
    }
  }, [role]);

  const initOpenSections = useCallback(() => {
    setOpenSections(
      menuList.reduce((acc, item) => {
        acc[item.key] = true;
        return acc;
      }, {} as Record<string, boolean>)
    );
  }, [menuList]);

  useEffect(() => {
    initOpenSections();
  }, [initOpenSections]);

  return (
    <>
      {menuList.map((item) => {
        // 조건에 따라 메뉴 표시 여부 결정
        if (
          item.requiresAdmin &&
          role !== EmployeeRole.ADMIN &&
          role !== EmployeeRole.MANAGER
        ) {
          return null;
        }

        if (item.children) {
          return (
            <div key={item.key}>
              <ListItemButton
                sx={{
                  marginBottom: !openSections[item.key] ? "4px" : undefined,
                  paddingRight: "28px",
                  ...mainItemStyle,
                  display: "flex",
                  justifyContent: "space-between", // 양쪽 끝으로 정렬
                  alignItems: "center",
                }}
                onClick={() => toggleSection(item.key)}
              >
                <ItemTextWrapper>
                  <CustomText
                    type="subtitle"
                    cursorPointer
                    bold400
                    paddingLeft={"6px"}
                  >
                    {t(item.label as NamespaceKeys<"Menu">)}
                  </CustomText>
                </ItemTextWrapper>
                {openSections[item.key] ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse
                sx={{ ...collapseStyle }}
                in={openSections[item.key]}
                timeout="auto"
                unmountOnExit
              >
                <List
                  component="div"
                  disablePadding
                  onClick={
                    item.key === "register"
                      ? onChangeRegisterLeadState
                      : undefined
                  }
                >
                  {item.children.map((child) => {
                    if (
                      child.requiresAdmin &&
                      role !== EmployeeRole.ADMIN &&
                      role !== EmployeeRole.MANAGER
                    ) {
                      return null;
                    }

                    if (child.external && child.toUrl) {
                      return (
                        <ListItemButton
                          key={child.key}
                          sx={{ paddingLeft: "36px", ...collapseItemStyle }}
                          onClick={() => window.open(child.toUrl, "_blank")}
                        >
                          <ListItemText>
                            <CustomText type="body" cursorPointer>
                              {t(child.label as NamespaceKeys<"Menu">)}
                            </CustomText>
                          </ListItemText>
                        </ListItemButton>
                      );
                    }

                    if (child.toUrl) {
                      return (
                        <ListItemButton
                          key={child.key}
                          selected={location.pathname === child.toUrl}
                          component={Link}
                          to={child.toUrl}
                          sx={{ paddingLeft: "36px", ...collapseItemStyle }}
                          onClick={
                            type === "pc" ? onChangePageNumber : closeDrawer
                          }
                        >
                          <ListItemText>
                            <CustomText type="body" cursorPointer>
                              {t(child.label as NamespaceKeys<"Menu">)}
                            </CustomText>
                          </ListItemText>
                        </ListItemButton>
                      );
                    }
                    return null;
                  })}
                </List>
              </Collapse>
            </div>
          );
        }

        return null;
      })}
    </>
  );
};

export default DrawerMenuList;

interface ItemTextWrapperProps {
  children: React.ReactNode;
}

const ItemTextWrapper: React.FC<ItemTextWrapperProps> = ({ children }) => (
  <div>{children}</div>
);
const collapseStyle = {
  color: "rgb(221, 221, 221)",
  backgroundColor: "rgb(84, 84, 83)",
};

const collapseItemStyle = {
  "&&:hover": {
    color: "rgb(255, 255, 255)",
    backgroundColor: "rgb(84, 84, 83)",
    filter: "brightness(1.2)",
  },
  "&&.Mui-selected": {
    backgroundColor: "rgb(84, 84, 83)",
    filter: "brightness(1.2)",
  },
};

const mainItemStyle = {
  height: "45px",
  "&&.Mui-selected": {
    backgroundColor: "rgb(84, 84, 83, 0.05)",
  },
  "&&.Mui-selected:hover": {
    backgroundColor: "rgb(84, 84, 83, 0.05)",
  },
};

import { setCulture, loadCldr } from "@syncfusion/ej2-base";
import { L10n } from "@syncfusion/ej2-base";

// Korean locale data
import * as gregorian from "cldr-data/main/ko/ca-gregorian.json";
import * as numbers from "cldr-data/main/ko/numbers.json";
import * as timeZoneNames from "cldr-data/main/ko/timeZoneNames.json";
import * as NumberingSystems from "cldr-data/supplemental/numberingSystems.json";
import * as weekData from "cldr-data/supplemental/weekData.json";
import { useTranslation } from "react-i18next";

export const SyncfusionLocalization = () => {
  // Load CLDR data
  loadCldr(NumberingSystems, gregorian, numbers, timeZoneNames, weekData);
  const { t } = useTranslation("syncfusion");
  // Load translations for Syncfusion components
  L10n.load({
    ko: {
      datepicker: {
        today: t("Today"),
      },
      daterangepicker: {
        applyText: t("Select"),
        cancelText: t("Cancel"),
        endLabel: t("End Date"),
        selectedDays: t("Selected Days"),
        startLabel: t("Start Date"),
        days: t("Day"),
      },
      dropdowns: {
        noRecordsTemplate: t("Empty Record"),
      },
      schedule: {
        today: t("Today"),
        save: t("Save"),
        cancel: t("Cancel"),
        delete: t("Delete"),
        edit: t("Edit"),
        "New Event": t("New Event"),
        add: t("Add"),
        "Edit Event": t("Edit Event"),
        details: t("Details"),
        "Start Time": t("Start Time"),
        "End Time": t("End Time"),
        location: t("Location"),
        description: t("Description"),
        week: t("Week"),
        month: t("Month"),
        workWeek: t("WorkWeek"),
        day: t("Day"),
      },
      grid: {
        EmptyRecord: t("Empty Record"),
        EmptyDataSourceError: t("Empty Data Source Error"),
        Sort: t("Sort"),
        Clear: t("Clear"),
        SortAscending: t("Sort Ascending"),
        SortDescending: t("Sort Descending"),
        None: t("None"),
        Ascending: t("Ascending"),
        Descending: t("Descending"),
        OKButton: t("OK Button"),
        CancelButton: t("Cancel Button"),
        StartsWith: t("Starts With"),
        EndsWith: t("Ends With"),
        Contains: t("Contains"),
        Equal: t("Equal"),
        NotEqual: t("Not Equal"),
        LessThan: t("LessThan"),
        LessThanOrEqual: t("Less Than Or Equal"),
        GreaterThan: t("Greater Than"),
        GreaterThanOrEqual: t("Greater Than Or Equal"),
        ChooseDate: t("Choose Date"),
        EnterValue: t("Enter Value"),
        SelectAll: t("Select All"),
        Blanks: t("Blanks"),
        NoResult: t("No Result"),
        ClearFilter: t("Clear Filter"),
        TextFilter: t("Text Filter"),
        NumberFilter: t("Number Filter"),
        DateFilter: t("Date Filter"),
        DateTimeFilter: t("Date Time Filter"),
        Between: t("Between"),
        CustomFilter: t("Custom Filter"),
        CustomFilterPlaceHolder: t("Custom Filter PlaceHolder"),
        CustomFilterDatePlaceHolder: t("Custom Filter Date PlaceHolder"),
        AND: t("And"),
        OR: t("Or"),
        ShowRowsWhere: t("Show Rows Where"),
        MatchCase: t("Match Case"),
        SortAtoZ: t("Sort A to Z"),
        SortZtoA: t("Sort Z to A"),
        SortByOldest: t("Sort By Oldest"),
        SortByNewest: t("Sort By Newest"),
        AddCurrentSelection: t("Add Current Selection"),
        Search: t("Search"),
      },
      pager: {
        currentPageInfo: t("Current Page Info"),
        totalItemsInfo: t("Total Items Info"),
        firstPageTooltip: t("First Page Tooltip"),
        lastPageTooltip: t("Last Page Tooltip"),
        nextPageTooltip: t("Next Page Tooltip"),
        previousPageTooltip: t("Previous Page Tooltip"),
        nextPagerTooltip: t("Next Pager Tooltip"),
        previousPagerTooltip: t("Previous Pager Tooltip"),
      },
    },
  });
};

// Set culture to Korean
setCulture("ko");

import { ReactNode } from "react";
import { ContentWrapper, ContentBody } from "../../styles/theme";
import { useRecoilValue } from "recoil";
import { leftDrawerOpenState } from "../../system/atoms";

import Footer from "./Footer";
import { useDeviceType } from "../../hooks/useDeviceType";
interface ContentProps {
  children: ReactNode;
}

function Content({ children }: ContentProps) {
  const open = useRecoilValue(leftDrawerOpenState);
  const deviceType = useDeviceType();
  return (
    <>
      <ContentWrapper isOpen={open}>
        <ContentBody deviceType={deviceType}>{children}</ContentBody>
      </ContentWrapper>
      {/* Footer */}
      <Footer />
    </>
  );
}

export default Content;
